<template>
    <div class="BigBox">
        <div class="TopBox">
            <div class="Back">
                <img @click="$router.push('/')" src="../../assets/CulturalHomePage/PomePage.png" alt="" style="height: 100%; cursor: pointer; " />
            </div>
            <div class="User">
                <img @click="IntanPerson()" src="../../assets/CulturalHomePage/personal.png" alt="" style="height: 100%; cursor: pointer;" />
            </div>
            <div class="TopTitle">畅玩古镇</div>

        </div>
        <div class="MuseumBox">
            <!--底座-->
            <div class="rotation3D-baseMap">
                <div class="centerImg" @click="toMuseun">
                    <img :src="itemList[clickActive].centerImg" style="height:60%;position: relative;top: -2vh;">
                </div>

            </div>
            <!--旋转3D-->
            <div id="rotation3D" class="rotation3D">
                <button class="center">中心</button>
                <div class="itemList">
                    <div class="rotation3D__item" @click="DianJi(item)" :class="item.type" v-for="(item,index) in itemList" :key="index">
                        <div class="scale">
                            <div class="baseImg">
                                <img :src="item.img" style="width:100%;height:100%">
                            </div>
                            <div class="cont">
                                <i class="iconfont" :class="item.icon"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="lineList">
                    <div class="rotation3D__line" v-for="(item,index) in itemList" :class="item.type" :key="index">
                        <div v-if="item.type=='blue'" class="pos">
                            <svg width="10" height="400">
                                <path id="path1" d="M0 400, 0 0" stroke-dasharray="5,10" />
                            </svg>
                            <div class="dot dot1 el-icon-caret-right"></div>
                        </div>
                        <div v-if="item.type=='yellow'" class="pos">
                            <svg width="10" height="400">
                                <path id="path2" d="M0 400, 0 0" stroke-dasharray="5,10" />
                            </svg>
                            <div class="dot dot2"><i class="el-icon-close"></i></div>
                        </div>
                        <div v-if="item.type=='green'" class="pos" style="left: -16px;">
                            <svg width="50" height="400">
                                <path id="path3" d="M20 400 S 0 200, 20 0" stroke-dasharray="5,10" />
                                <path id="path4" d="M20 400 S 40 200, 20 0" stroke-dasharray="5,10" />
                            </svg>
                            <div class="dot dot3 el-icon-caret-right"></div>
                            <div class="dot dot4 el-icon-caret-right"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <TiShiBox ref="childRef" :title="title"></TiShiBox>
    </div>
</template>

<script>
import $ from 'jquery'
import TiShiBox from '../Public/TiShiBox.vue'

export default {
    data () {
        return {
            itemList: [
                { name: '靖港古镇', type: 'blue', icon: 'icon-renyuanguanli', img: require('../../assets/GameList/JGGZ.png'), centerImg: require('../../assets/HomePage/HomePageTwo/JGGZ.png') },
                { name: '铜官古镇', type: 'blue', icon: 'icon-shujufuwuzhongxin', img: require('../../assets/GameList/TGGZ.png'), centerImg: require('../../assets/HomePage/HomePageTwo/TGGZ.png') },
                { name: '新康古镇', type: 'blue', icon: 'i con-liangchang', img: require('../../assets/GameList/XKGZ.png'), centerImg: require('../../assets/HomePage/HomePageTwo/XKGZ.png') },
                { name: '乔口古镇', type: 'blue', icon: 'icon-tanpuyashifuwu', img: require('../../assets/GameList/QKGZ.png'), centerImg: require('../../assets/HomePage/HomePageTwo/QKGZ.png') },
                { name: '书堂山古镇', type: 'blue', icon: 'icon-lumianshigong', img: require('../../assets/GameList/STSGZ.png'), centerImg: require('../../assets/HomePage/HomePageTwo/STSGZ.png') },
            ],
            active: 0,
            clickActive: 0,//点击
            title:1,
        }
    },
    mounted () {
        let t = this
        var cancelFrame = window.cancelAnimationFrame || window.cancelRequestAnimationFrame;
        var requestFrame = window.requestAnimationFrame;
        var time = !window.performance || !window.performance.now ?
            function () { return +new Date() } :
            function () { return performance.now() };

        /**
         * 计算两点距离
         * @param points
         * @returns {number}
         * distance([{x:0,y:0},{x:1,y:1}]);
         */
        var distance = function (points) {
            var p1 = points[0];
            var p2 = points[1];
            var a = p2.x - p1.x;
            var b = p2.y - p1.y;
            return Math.sqrt(a * a + b * b);
        };

        /**
         * 圆公式
         * @param rotation 弧度
         * 计算公式：
         * Math.PI;     //圆周率
         * Math.sin();  //正弦 x      -左 +右
         * Math.cos;    //余弦 y      -下 +上
         */
        var circleMath = {
            /**
             * 根据弧度计算角度
             * @param rotation 弧度
             * rotation, farScale, xs, xr, ys, yr, itemWidth
             */
            // parseRotate: function (rotation) {
            //     return (180 / Math.PI * rotation) - 180;
            // },
            parseRotate: function (rotation, self) {
                var sin = Math.sin(rotation), cos = Math.cos(rotation);
                var sin_cos = sin * cos;  //得出偏移正负值，从0°向左依次 +-+-
                var angle = (180 / Math.PI * rotation) - 180;
                var lastAngle = angle;
                lastAngle = angle + (self.yr * (sin_cos / (Math.PI + 1)));

                return lastAngle;
            },
            /**
             * 计算scale,x,y
             * scale    最小尺寸 + ((1 - 最小尺寸) * (sin正弦 + 1) * 0.5)
             * x        x起点 + (尺寸 * cos余弦 * x半径) - 元素宽度一半
             * y        y起点 + (尺寸 * sin正弦 * x半径) - 元素宽度一半
             * farScale, xs, xr, ys, yr, itemWidth
             */
            parseSXY: function (rotation, self) {

                var farScale = self.farScale;
                var itemWidth = self.itemWidth;
                var xs = self.xs; var xr = self.xr; var ys = self.ys; var yr = self.yr;
                var sin = Math.sin(rotation), cos = Math.cos(rotation);
                var scale = farScale + ((1 - farScale) * (sin + 1) * 0.5);  //单个尺寸

                // 按设置尺寸
                // var x = xs + (scale * cos * xr) - (itemWidth * 0.5);
                // var y = ys + (scale * sin * yr) - (itemWidth * 0.5);
                // 不使用压缩
                // var x = xs + (cos * xs) - (itemWidth * 0.5);
                // var y = ys + (sin * ys) - (itemWidth * 0.5);
                // 使用压缩
                var x = xs + (cos * xr) - (itemWidth * 0.5);
                var y = ys + (sin * yr) - (itemWidth * 0.5);
                var distanceNumber = distance([
                    { x: self.$rotation.width() / 2 - self.$item.width() / 2, y: self.$rotation.height() / 2 - self.$item.height() / 2 },
                    { x: x, y: y }]
                );
                return {
                    x: x,
                    y: y,
                    scale: scale,
                    distanceNumber: distanceNumber,
                }
            },
        }
        /**
         * 3D旋转
         * @param id
         */
        var Rotation3D = window.Rotation3D = function (_opts) {
            var self = this;
            this.$rotation = $(_opts.id)
            this.$lineList = this.$rotation.find('.lineList')
            this.$item = this.$rotation.find('.rotation3D__item')
            this.$line = this.$rotation.find('.rotation3D__line')
            this.itemWidth = this.$item.width();
            this.itemHeight = this.$item.height();
            this.length = this.$item.length;
            // 圆计算
            this.rotation = Math.PI / 2;    //圆周率/2
            this.destRotation = this.rotation;

            var xr = this.$rotation.width() * 0.5;
            var yr = this.$rotation.height() * 0.5;
            var xRadius = _opts.xRadius || 0;
            var yRadius = _opts.yRadius || 0;

            var opts = Object.assign({
                farScale: 1,        // 最小尺寸
                xs: xr,             // x起点
                ys: yr,             // y起点
                xr: xr - xRadius,   // x半径-压缩
                yr: yr - yRadius,	// y半径-压缩
                // 播放
                autoPlay: false,
                autoPlayDelay: 3000,
                currenIndex: -1,
                fps: 30,
                speed: 4,
            }, _opts)
            Object.assign(this, opts)

            // 遍历子元素
            this.$item.each(function (index) {
                $(this).click(function () {
                    $(this).addClass('active').siblings().removeClass('active')
                    self.goTo(index)
                })
            })
            // 当前控件进入离开
            this.$rotation.mouseenter(function () {
                clearInterval(self.autoPlayTimer)
            })
            this.$rotation.mouseleave(function () {
                self.onAutoPlay()
            })

            this.onAutoPlay()
            this.onDrag()
            this.render()

        }
        /**
         * item样式
         * x    x起点 + (尺寸 * 余弦 * x压缩) - 元素宽度一半
         * y    y起点 + (尺寸 * 正弦 * y压缩) - 元素宽度一半
         */
        Rotation3D.prototype.itemStyle = function ($item, index, rotation) {

            var parseSXY = circleMath.parseSXY(rotation, this);
            var scale = parseSXY.scale;
            var x = parseSXY.x;
            var y = parseSXY.y;
            var $line = this.$lineList.find('.rotation3D__line').eq(index);

            //设置当前子菜单的位置（left,top） = (x,y)
            $item.find('.scale').css({
                'transform': `scale(${scale})`,
                // 'top': `${this.itemWidth * (1-scale) }`,
            })
            $item.css({
                position: 'absolute',
                display: 'inline-block',
                // opacity: scale,
                'z-index': parseInt(scale * 100),
                'transform-origin': '0px 0px',
                // 'transform': `translate(${x}px, ${y}px) scale(${scale})`,
                'transform': `translate(${x}px, ${y}px)`,
            });

            /**
            * 线样式
            */
            $line.css({
                height: parseSXY.distanceNumber,
            })
            $line.find('svg').css({
                height: parseSXY.distanceNumber,
            })
            $line.find('.dot1').css({
                'offset-path': `path("M0 ${parseSXY.distanceNumber}, 0 0")`,
            })
            $line.find('#path1').attr({
                'd': `M0 ${parseSXY.distanceNumber}, 0 0`,
            })

            $line.find('.dot2').css({
                'offset-path': `path("M0 ${parseSXY.distanceNumber / 2}, 0 0")`,
            })
            $line.find('#path2').attr({
                'd': `M0 ${parseSXY.distanceNumber}, 0 0`,
            })

            $line.find('.dot3').css({
                'offset-path': `path("M20 ${parseSXY.distanceNumber} S 0 ${parseSXY.distanceNumber / 2}, 20 0")`,
            })
            $line.find('#path3').attr({
                'd': `M20 ${parseSXY.distanceNumber} S 0 ${parseSXY.distanceNumber / 2}, 20 0`,
            })

            $line.find('.dot4').css({
                'offset-path': `path("M20 0 S 40 ${parseSXY.distanceNumber / 2}, 20 ${parseSXY.distanceNumber}")`,
            })
            $line.find('#path4').attr({
                'd': `M20 0 S 40 ${parseSXY.distanceNumber / 2}, 20 ${parseSXY.distanceNumber}`,
            })

        }
        /**
        * line样式
        */
        Rotation3D.prototype.lineStyle = function ($line, index, rotation) {

            var rotate = circleMath.parseRotate(rotation, this)

            $line.css({
                transform: 'rotate(' + rotate + 'deg)',
            })
            this.$lineList.css({
                // transform: `rotateX(${this.yRadius / 3}deg)`,
            })
        }

        /**
        * 旋转至index
        */
        Rotation3D.prototype.goTo = function (index) {
            var self = this;
            this.currenIndex = index;
            setTimeout(() => {
                t.clickActive = index
            }, 1000)
            /**
            * 1.计算floatIndex,用于控死amdiff
            */
            var itemsRotated = this.length * ((Math.PI / 2) - this.rotation) / (2 * Math.PI);
            var floatIndex = itemsRotated % this.length;
            if (floatIndex < 0) { floatIndex = floatIndex + this.length; }

            /**
            * 2.计算diff,判断方向正反
            */
            var diff = index - (floatIndex % this.length);
            if (2 * Math.abs(diff) > this.length) {
                diff -= (diff > 0) ? this.length : -this.length;
            }
            // 停止任何正在进行的旋转
            this.destRotation += (2 * Math.PI / this.length) * -diff;
            this.scheduleNextFrame();

        }
        /**
        * 定时器渐近旋转
        */
        Rotation3D.prototype.scheduleNextFrame = function () {
            var self = this
            this.lastTime = time();
            // 暂停
            var pause = function () {
                cancelFrame ? cancelFrame(self.timer) : clearTimeout(self.timer);
                self.timer = 0;
            }
            // 渐进播放
            var playFrame = function () {
                var rem = self.destRotation - self.rotation;
                var now = time(), dt = (now - self.lastTime) * 0.002;
                self.lastTime = now;
                // console.log('rem',rem)

                if (Math.abs(rem) < 0.003) {
                    self.rotation = self.destRotation;
                    pause();
                } else {
                    // 渐近地接近目的地
                    self.rotation = self.destRotation - rem / (1 + (self.speed * dt));
                    self.scheduleNextFrame();
                }
                self.render();
            }

            this.timer = cancelFrame ?
                requestFrame(playFrame) :
                setTimeout(playFrame, 1000 / this.fps);
        }
        /**
        * 更新
        */
        Rotation3D.prototype.render = function () {
            var self = this;

            // 图形间隔：弧度
            var spacing = 2 * Math.PI / this.$item.length;
            var itemRotation = this.rotation;
            var lineRotation = this.rotation + (Math.PI / 2);

            this.$item.each(function (index) {
                self.itemStyle($(this), index, itemRotation)
                itemRotation += spacing;
            })
            this.$line.each(function (index) {
                self.lineStyle($(this), index, lineRotation)
                lineRotation += spacing;
            })
        }
        /**
        * 自动播放
        */
        Rotation3D.prototype.onAutoPlay = function () {
            var self = this;

            if (this.autoPlay) {
                this.autoPlayTimer = setInterval(function () {
                    if (self.currenIndex < 0) {
                        self.currenIndex = self.length - 1
                    }
                    self.goTo(self.currenIndex);
                    self.currenIndex--; //倒叙
                }, this.autoPlayDelay)
            }
        }
        /**
        * 拖拽
        */
        Rotation3D.prototype.onDrag = function () {
            var self = this;
            var startX, startY, moveX, moveY, endX, endY;

            // 拖拽：三个事件-按下 移动 抬起
            //按下
            this.$rotation.mousedown(function (e) {
                startX = e.pageX; startY = e.pageY;

                // 移动
                $(document).mousemove(function (e) {
                    // console.log('移动');
                    endX = e.pageX; endY = e.pageY;
                    moveX = endX - startX; moveY = endY - startY;
                    // console.log('x,y',moveX,moveY);
                })
                // 抬起
                $(document).mouseup(function (e) {
                    endX = e.pageX; endY = e.pageY;
                    moveX = endX - startX; moveY = endY - startY;

                    // 每40旋转一步
                    var moveIndex = parseInt(Math.abs(moveX) / 50)
                    if (moveIndex > 0) {
                        // console.log(moveX<0 ? '向左' : '向右')
                        if (moveX < 0) { //向左
                            self.currenIndex = self.currenIndex - moveIndex
                            play(moveIndex)
                        } else { //向右
                            self.currenIndex = self.currenIndex + moveIndex
                            play(moveIndex)
                        }
                    }

                    // 解绑
                    $(document).unbind("mousemove");
                    $(document).unbind("mouseup");
                })

            })

            function play () {
                if (self.currenIndex == 0) {
                    self.currenIndex = self.length - 1
                }
                self.goTo(self.currenIndex % self.length);
            }

        }
        new Rotation3D({
            id: '#rotation3D',
            farScale: 0.6,
            // farScale: 1,
            xRadius: 0,  //x半径压缩
            yRadius: 220,  //y半径压缩
            // yRadius: 0,  //y半径压缩
            // autoPlay:true,
            // autoPlayDelay:6000,
        })
    },
    components: {
        TiShiBox
    },
    methods: {
        toMuseun () {
            let name = this.itemList[this.clickActive].name
            if (name == '铜官古镇') {
                window.open('http://8.138.105.53/')
            } else {
                this.$refs.childRef.ChuFa()
            }
        },
        IntanPerson () {
            // 进入页面就判断是否有token  如果没有那就继续登陆 如果有就进入个人中心
            if (this.$cookies.get('token') == null) {
                this.$router.push('/login')
            } else {
                this.$router.push('/personalCenter')
            }
        },
        // 预准备
        DianJi (e) {
            let name = e.name
            setTimeout(() => {
                if (name == '铜官古镇') {
                window.open('http://8.138.105.53/')
            } else {
                this.$refs.childRef.ChuFa()
            }
            },1000)
        }
    },
}
</script>
<style lang="less" scoped>
.rotation3D-baseMap {
    position: absolute;
    left: 10px;
    right: 0;
    top: 160px;
    margin: auto;
    width: 80%;
    height: 80%;
    background: url('../../assets/GameList/baseMap.png');
    background-size: 100% 100%;
}
// .rotation3D-baseMap::before {
.centerImg {
    position: absolute;
    left: -12px;
    right: 0;
    top: 12vh;
    margin: auto;
    z-index: 99;
    width: 25%;
    height: 40%;
    display: block;
    content: '';
    display: flex;
    justify-content: center;
    align-items: center;
    animation: 10s bounceUpDown infinite;
    background: url('../../assets/GameList/imhBg.png') no-repeat;
    background-size: 100% 100%;
    .move {
        position: absolute;
        bottom: 10vh;
    }
}
/*
  椭圆会使内部失真    transform: rotateX(50deg);
*/
.rotation3D {
    position: relative;
    width: 800px;
    height: 800px;
    cursor: move;
    user-select: none;
    margin: 0 auto;
    margin-top: -100px;
    /*border: 1px solid white; border-radius: 100%;*/
}
.rotation3D .center {
    display: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.rotation3D .itemList {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 20;
}
.rotation3D .lineList {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    transform-style: preserve-3d;
}
@media (min-height: 1000px) {
    .rotation3D .itemList {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 20;
        top: 12vh;
        transform: scale(1.7);
    }
    .rotation3D .lineList {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 10;
        top: 12vh;
        transform-style: preserve-3d;
        transform: scale(1.7);
    }
}

/*---------------------------点样式---------------------------*/
.rotation3D__item {
    position: absolute;
    display: block;
    cursor: pointer;
    width: 161px;
    height: 188px;
    text-align: center;
    line-height: 30px;
    font-size: 16px;
    color: white;
    /*background: #2292ef; border-radius: 4px;*/
}
.rotation3D__item .scale {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
}
.rotation3D__item .cont {
    position: relative;
    z-index: 2;
}
.rotation3D__item .cont .iconfont {
    font-size: 28px;
    margin-top: 30px;
    margin-bottom: 60px;
    display: block;
}
.rotation3D__item .cont p {
    color: #fff;
}

.rotation3D__item.blue {
    color: #01e9fc;
}
.rotation3D__item.green {
    color: #02e943;
}
.rotation3D__item.yellow {
    color: #ffd200;
}

/*底座*/
.rotation3D__item .baseImg {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.rotation3D__item.blue .baseImg {
    // background: url('../../assets/GameList/LanDi.png');
}
.rotation3D__item.green .baseImg {
    // background: url('../../assets/GameList/LvDi.png');
}
.rotation3D__item.yellow .baseImg {
    // background: url('../../assets/GameList/HuangDi.png');
}

/*---------------------------
    线样式
    线高为总高的一般
---------------------------*/
.rotation3D__line {
    position: absolute;
    left: 50%;
    top: 50%;
    display: block;
    width: 2px;
    height: 50%;
    padding-top: 60px;
    color: #fff;
    font-size: 50px;
    /*background: #fff;*/
    /*原点设置在中间*/
    transform-origin: 50% 0;
    transform-style: preserve-3d;
}
.rotation3D__line .pos {
    position: absolute;
    top: 0;
}
.rotation3D__line svg {
    position: absolute;
    top: 0;
}
.rotation3D__line svg path {
    stroke: #fff;
    fill: none;
    stroke-width: 3;
    animation: path-animation 100s linear 0s infinite normal;
}
@keyframes path-animation {
    0% {
        stroke-dashoffset: 500;
    }
    100% {
        stroke-dashoffset: 0;
    }
}
.rotation3D__line .dot {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    /*width: 35px; height: 35px; font-size: 35px; */
    width: 24px;
    height: 24px;
    font-size: 24px;
}
.rotation3D__line .dot1,
.rotation3D__line .dot3,
.rotation3D__line .dot4 {
    animation: svg-path-animation 6s ease-in-out 0s infinite normal;
}
.rotation3D__line .dot1 {
    offset-path: path('M0 400, 0 0');
    offset-distance: 0%;
}
.rotation3D__line .dot2 {
    offset-path: path('M0 200, 0 0');
    offset-distance: 0%;
    background: #ffd200;
    border-radius: 100%;
    font-size: 22px;
    color: #000;
}
.rotation3D__line .dot3 {
    offset-path: path('M20 400 S 0 200, 20 0');
    offset-distance: 0%;
}
.rotation3D__line .dot4 {
    offset-path: path('M20 0 S 40 200, 20 400');
    offset-distance: 0%;
}
@keyframes svg-path-animation {
    from {
        offset-distance: 0%;
    }
    to {
        offset-distance: 100%;
    }
}

/*颜色*/
.rotation3D__line.blue {
    color: #07b2f9;
}
.rotation3D__line.green {
    color: #00ff5b;
}
.rotation3D__line.yellow {
    color: #ffd500;
}

.rotation3D__line.blue svg path {
    stroke: #07b2f9;
}
.rotation3D__line.green svg path {
    stroke: #00ff5b;
}
.rotation3D__line.yellow svg path {
    stroke: #ffd500;
}
.BigBox {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    background-image: url('../../assets/HomePage/HomePageTwo/HomepageBG.png');
    background-size: 100% 100%;
    background-color: #010e30;
    overflow: hidden;
    .TopBox {
        height: 10%;
        position: relative;
        .TopTitle {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: rgb(215, 238, 253);
            font-size: 5vh;
            font-family: 'YouShe';
            letter-spacing: 0.4rem;
        }
        .Back {
            position: absolute;
            width: 7.5vh;
            height: 7vh;
            left: 10%;
            top: 3vh;
        }
        .User {
            position: absolute;
            width: 7.5vh;
            height: 7vh;
            right: 10%;
            top: 3vh;
        }
    }
    .MuseumBox {
        width: 80%;
        margin: 1% auto;
        height: 80%;
        color: #fff;
        overflow: hidden;
    }
}
</style>